import { ClearStoreService } from './../../clear-store/clear-store.service';
import {
    FirstLoginRequest,
    LoginRequest,
    LoginResponse,
    LoginResponseData,
    MailItem,
    RecoverPasswordRequest,
    ResetPasswordRequest,
} from './../../../models/login/login.model';
import { tap, map } from 'rxjs/operators';
import { APP_ROUTES } from './../../../constants/routes/app-routes.const';
import { StorageService } from './../../../core/storage/storage.service';
import { HttpService } from './../../../core/http/http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private _isLoggedIn: boolean;

    constructor(
        private http: HttpService,
        private storage: StorageService,
        private router: Router,
        private clearStore: ClearStoreService
    ) {}

    public login(data: LoginRequest): Observable<LoginResponseData> {
        return this.http.post<LoginResponse>('login', data).pipe(
            tap((res: any) => {
                if (res?.data?.token) {
                    this.setisLoggedIn();
                }
            }),
            map((res) => res.data)
        );
    }

    public firstLogin(data: FirstLoginRequest): Observable<any> {
        return this.http.post<any>('register', data).pipe(
            map((res) => res.data)
        );
    }

    public activateAccont(token: string): Observable<any> {
        return this.http.get<any>(`validate-email/${token}`).pipe(
            map((res) => res.data)
        );
    }

    public getMailList(cpf: string): Observable<MailItem[]>{
        return this.http.get<any>(`list-email/${cpf}`).pipe(
            map((res) => res.data)
        );
    }

    public recoverPassword(data: RecoverPasswordRequest): Observable<any> {
        return this.http.post<any>('recover-password', data).pipe(
            map((res) => res.data)
        );
    }

    public resetPassword(data: ResetPasswordRequest): Observable<any> {
        return this.http.post<any>('change-password', data).pipe(
            map((res) => res.data)
        );
    }

    public isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public setisLoggedIn(isLogged: boolean = true): void {
        this._isLoggedIn = isLogged;
    }

    public logOut(): Observable<any> {
        return this.http.post<LoginResponse>('logout', {});
    }

    public clearLoginData(): void {
        this.clearStore.clearStore();
        this.setisLoggedIn(false);
        this.storage.removeToken();
        this.router.navigate([APP_ROUTES.ACCOUNT]);
    }
}
