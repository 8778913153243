import { BehaviorSubject } from 'rxjs';

/**
 * Esta clase contiene los atributos name y subject y funciona como un Map, cuando usada en un Array.
 */
class SubjectMap {
    name: string;
    subject: BehaviorSubject<any>;
    constructor(name: string) {
        this.name = name;
        this.subject = new BehaviorSubject(null);
    }
}

export class StoreHandler {
    /**
     * Acá se guardan los valores del map.
     */
    private storeArray: Array<SubjectMap> = [];

    constructor() {}

    /**
     * Métodos para crear un nuevo map o retornar uno existente
     * @param param parametro usado para darle nombre al map.
     */
    protected getSubject<T>(param: any): BehaviorSubject<T> {
        if (!this.storeArray.some((res) => res.name === param.toString())) {
            this.storeArray.push(new SubjectMap(param.toString()));
        }
        return this.storeArray.find((res) => res.name === param.toString())
            .subject;
    }

    public clearStore(): void {
        this.storeArray.splice(0, this.storeArray.length);
    }
}
