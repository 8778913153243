import { APP_ROUTES } from './../../constants/routes/app-routes.const';
import { LoginService } from '@app/services/api/login/login.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';


/**
 * Guard for the App.
 */
@Injectable({ providedIn: 'root' })
export class AuthAppService implements CanActivate {

  constructor (private router: Router, private login: LoginService) { }

  canActivate() {
    let canActivate = this.login.isLoggedIn();

    if (!canActivate) {
      this.router.navigateByUrl(APP_ROUTES.ACCOUNT)
    }

    return !!canActivate;
  }
}
