import { HttpService } from './../../../core/http/http.service';
import { environment } from './../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
    LoanAmortizeResponse,
    LoanAmortizeResponseData,
    LoanHistoricResponse,
    LoanHistoricResponseData,
    LoanMovementsResponse,
    LoanMovementsResponseData,
} from './../../../models/loan/loan.model';
import { Observable } from 'rxjs';
import { StoreService } from './../../../core/store/store.service';
import { Injectable } from '@angular/core';
import { StoreHandler } from '@app/core/store/store-handler.class';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoanService extends StoreHandler {
    constructor(
        private store: StoreService,
        private http: HttpClient,
        private httpService: HttpService
    ) {
        super();
    }

    public getHistoricoSolicitacoes(): Observable<LoanHistoricResponseData[]> {
        return this.store
            .get<LoanHistoricResponse>(
                `users/loans`,
                this.getSubject('historico-solicitacoes')
            )
            .pipe(map((res) => res.data));
    }

    public getContractInfo(
        contract: string
    ): Observable<LoanAmortizeResponseData> {
        return this.httpService
            .get<LoanAmortizeResponse>(`users/loans/amortisation/${contract}`)
            .pipe(
                map((res) => {
                    res.data.saldo_adimplente = res.data.saldo_adimplente.replace(',', '.');
                    res.data.saldo_total = res.data.saldo_total.replace(',', '.');
                    res.data.prestacoes_aberto = res.data.prestacoes_aberto.replace(',', '.');
                    return res.data;
                })
            );
    }

    public getMovements(
        contract: string
    ): Observable<LoanMovementsResponseData> {
        return this.httpService
            .get<LoanMovementsResponse>(`v2/loan/moviment/${contract}`)
            .pipe(map(res => res.data));
    }

    public getLiquidationHeader(
        contract: string
    ): Observable<LoanMovementsResponseData> {
        return this.httpService
            .get<LoanMovementsResponse>(`v2/loan/liquidate/${contract}`)
            .pipe(map(res => res.data));
    }

    public getMovementPayment(
        contract: string,
        movement: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http
            .get<any>(`${environment.baseUrl}v2/loan/moviment/${contract}/${movement}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            });
    }

    public liquidateContract(
        contract: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http
            .get<any>(`${environment.baseUrl}v2/loan/liquidate/${contract}/pay`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            });
    }

    public amortizeContract(
        contract: string,
        value: number
    ): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http
            .get<any>(`${environment.baseUrl}v2/loan/amortisation/${contract}/${value}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            });
    }

    public getExtratoIr(ano: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}users/loans/ir/${ano}/pdf`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public getContract(idContrato: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}users/loans/contract/${idContrato}/pdf`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }
    public getLoanAbstract(idContrato: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}v2/loan/extract/${idContrato}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }
}
