import { Observable } from 'rxjs';
import { RestService } from './rest.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private rest: RestService) { }


  public get<T>(url: string): Observable<T> {
    return this.rest.get<T>(url)
  }

  public getAddress<T>(url: string): Observable<T> {
    return this.rest.getAddress<T>(url);
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.rest.post<T>(url, body);
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.rest.put<T>(url, body);
  }

  public delete<T>(url: string): Observable<T> {
    return this.rest.delete<T>(url);
  }

}
