export const STORAGE = {
      USER_TOKEN: "fcs_user_token",
      USER_ID: "user_id",
      USER_NAME: "user_name",
      UPDATE_USER_DATA: "update_user_data"
}

export const UPDATE_DATA_STATUS = {
    IS_UPDATED: "0",
    REMEMBER_LATER: "1",
};
