export const APP_ROUTES = {
    ACCOUNT: 'account',
    LOGIN: 'login',
    FIRST_LOGIN: 'first-login',
    RECOVER_PASSWORD: 'recover-password',
    RESET_PASSWORD: 'reset-password',
    ACCOUNT_ACTIVATE: 'activate',
    MAIN: 'main',
    HOME: 'home',
    PLAN: 'plan',
    LOAN: 'loan',
    PROFILE: 'profile',
    PEP: 'pep',
    CONSENT: 'consent',
    HELP: 'help',
    FAQ: 'faq',
    DOCUMENTS: 'documents',
    DOCUMENT_DOWNLOAD: 'download',
    CANCEL_SUBSCRIPTION: 'cancel-subscription',
    LOAN_ABSTRACT: 'loan-abstract',
    LOAN_AMORTIZE: 'loan-amortize',
    LOAN_AMORTIZE_PAYMENT: 'loan-amortize-payment',
    LOAN_HISTORIC: 'loan-historic',
    BENEFIT_SIMULATION: 'benefit-simulation',
    BENEFIT_REQUEST: 'benefit-request',
    LOAN_SIMULATION: 'loan-simulation',
    LOAN_SIMULATION_START: 'loan-simulation-start',
    LOAN_SIMULATION_RESULT: 'loan-simulation-result',
    LOAN_SIMULATION_RESULT_TABLE: 'loan-simulation-result-table',
    LOAN_SIMULATION_BANK_DATA: 'loan-simulation-bank-data',
    PROOF_LIFE: 'proof-life',
    PROOF_LIFE_REGISTER: 'register',
    PROOF_LIFE_AUTH: 'auth',
};
