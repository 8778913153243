import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DEFAULT_HEADERS } from '@app/constants/http/http.const';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor (protected httpClient: HttpClient) {}

  public get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, { headers: DEFAULT_HEADERS })
  }

  public getAddress<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(environment.cepUrl + url, { headers: DEFAULT_HEADERS })
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, JSON.stringify(body), { headers: DEFAULT_HEADERS });
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(environment.baseUrl + url, JSON.stringify(body), { headers: DEFAULT_HEADERS });
  }

  public delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(environment.baseUrl + url, { headers: DEFAULT_HEADERS });
  }

}
