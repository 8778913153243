import { STORAGE } from '@app/constants/storage/storage.const';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor () { }

  public setToken(token: string): void {
    localStorage.setItem(STORAGE.USER_TOKEN, token);
  }

  public getToken(): string {
    return localStorage.getItem(STORAGE.USER_TOKEN);
  }

  public removeToken(): void {
    localStorage.removeItem(STORAGE.USER_TOKEN);
  }

  public addItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public getItem(key: string): string {
    return localStorage.getItem(key);
  }
  public removeAll(): void {
    localStorage.clear();
  }

}
