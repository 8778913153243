import { HttpService } from '../http/http.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
      providedIn: 'root'
})
export class StoreService {


      constructor (private http: HttpService) { }

      public get<T>(url: string, stream: BehaviorSubject<T>, force?: boolean): Observable<T> {
            if (stream.value && !force) {
                  return stream.asObservable();
            }
            else {
                  return this.http.get<T>(url).pipe(tap(res => stream.next(res)));
            }
      }

      public post<T>(url: string, body: any, stream: BehaviorSubject<T>, force?: boolean): Observable<T> {
            if (stream.value && !force) {
                  return stream.asObservable();
            }
            else {
                  return this.http.post<T>(url, body).pipe(tap(res => stream.next(res)));
            }
      }

}
