import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    FolderData,
    FolderResponse,
    DocumentRespose,
    DocumentData,
} from './../../../models/documents/documents.model';
import { HttpService } from './../../../core/http/http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private http: HttpService, private httpClient: HttpClient) {}

    public getFolders(): Observable<FolderData[]> {
        return this.http
            .get<FolderResponse>(`folders`)
            .pipe(map((res) => res.data));
    }

    public getDocuments(folderId: number): Observable<DocumentData[]> {
        return this.http
            .get<DocumentRespose>(`folders/${folderId}/documents`)
            .pipe(map((res) => res.data));
    }

    public downloadFile(documentId: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.httpClient.get<any>(
            `${environment.baseUrl}document/${documentId}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }
}
