import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { DocumentsService } from './../../../../services/api/documents/documents.service';
import { Component, OnInit } from '@angular/core';
import { concatMap } from 'rxjs/operators';

@Component({
    selector: 'app-document-download',
    templateUrl: './document-download.component.html',
    styleUrls: ['./document-download.component.scss'],
})
export class DocumentDownloadComponent implements OnInit {
    private parent: number;
    private id: number;
    private filename: string;
    constructor(
        private documents: DocumentsService,
        private route: ActivatedRoute
    ) {
        this.parent = this.route.snapshot.params.parent;
        this.id = this.route.snapshot.params.id;
        this.getDocuments();
    }

    ngOnInit(): void { }

    private getDocuments(): void {
        this.documents.getDocuments(this.parent).pipe(concatMap((res) => {
            this.filename = res.find(res => res.id == this.id).title;
            
            return this.documents.downloadFile(this.id);
        })).subscribe((res) => {
            const blob = new Blob([res.body], {
                type: 'content-type: application/pdf',
            });
            
            saveAs(blob, `${this.filename}.pdf`);
            setTimeout(() => {
                window.close();
            }, 1000)
        });
    }
}
