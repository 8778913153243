import { BenefitRequest } from './../../../models/plan/benefit-request.model';
import { HttpService } from './../../../core/http/http.service';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StoreHandler } from './../../../core/store/store-handler.class';
import { StoreService } from './../../../core/store/store.service';
import { map, tap } from 'rxjs/operators';
import {
    PlanBasicResponse,
    PlanBasicResponseData,
} from './../../../models/plan/plan.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class PlanService extends StoreHandler {
    private _isPensionista: string;

    constructor(
        private store: StoreService,
        private http: HttpClient,
        private rest: HttpService
    ) {
        super();
    }

    public getUserPlan(): Observable<PlanBasicResponseData> {
        return this.store
            .get<PlanBasicResponse>(`users/plan`, this.getSubject('basic-plan'))
            .pipe(
                map((res) => res.data),
                tap((res) => {
                    this._isPensionista = res.bit_pensionista;
                })
            );
    }

    get isPensionista(): boolean {
        return this._isPensionista === '1';
    }

    public cancelSubscription(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.post<any>(
            `${environment.baseUrl}users/subscription/cancel`,
            {},
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public solicitarBeneficio(data: FormData): Observable<any> {
        return this.http.post<any>(
            `${environment.baseUrl}users/pensions/benefits/requirement`,
            data,
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    public metodologiaCalculo(): Observable<any> {

        const base = environment.baseUrl.replace("api/", "");

        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${base}pdf/calculation-methodology`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public simulateBenefit(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}v2/pensions/benefits/simulate`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public getExtratoReservaPoupanca(
        from: string,
        to: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}users/pensions/contributions/pdf/${from}/${to}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public getInformeRendimento(ano: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        // `${environment.baseUrl}users/pensions/benefits/${ano}/pdf`,
        return this.http.get<any>(
            `${environment.baseUrl}v2/pensions/benefits/pensions/report-income/${ano}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public solicitarInstituto(data: FormData): Observable<any> {
        return this.http.post<any>(
            `${environment.baseUrl}institute/send`,
            data,
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            });
    }

    public getContracheque(ano: string, mes: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        if (mes.length === 1) mes = '0' + mes;
        return this.http.get<any>(
            `${environment.baseUrl}users/pensions/payslip/${ano}/${mes}`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public getExtratao(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}users/pensions/extract`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }

    public getExtratoInstituto(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-type': 'application/pdf',
            Accept: 'application/pdf',
        });
        return this.http.get<any>(
            `${environment.baseUrl}users/pensions/institutes/pdf`,
            {
                observe: 'response',
                headers: headers,
                responseType: 'blob' as 'json',
            }
        );
    }
}
