<div class="wrapper">
    <div class="flex flex-column">
        <div class="logo"><img src="assets/logotipo-horizontal.png" alt=""></div>
        <div class="flex-1 flex align-items-center justify-content-center">
            <div class="main">
                <span class="title">Baixando Documento Corporativo</span>
                <span class="subtitle">Aguarde enquando fazemos o download</span>
                <div class="icon">
                    <img src="assets/download.png" alt="">
                </div>
                <span class="subtitle">Esta janela se fechará automáticamente<br>quando o download for concluído</span>
            </div>
        </div>
    </div>
</div>
