import { APP_ROUTES } from './constants/routes/app-routes.const';
import { LoginService } from '@app/services/api/login/login.service';
import { StorageService } from './core/storage/storage.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        private storage: StorageService,
        private login: LoginService,
        private router: Router
    ) {
        if (this.storage.getToken()) {
            this.login.setisLoggedIn(true);
        }
    }
}
