import { StoreHandler } from './../../../core/store/store-handler.class';
import { StoreService } from './../../../core/store/store.service';
import { map } from 'rxjs/operators';
import {
    UserResponse,
    UserResponseData,
} from './../../../models/user/user.model';
import { STORAGE } from '@app/constants/storage/storage.const';
import { StorageService } from './../../../core/storage/storage.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
    providedIn: 'root',
})
export class UsuarioService extends StoreHandler {
    private _userId: string;
    public userNameObs$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        private store: StoreService, 
        private storage: StorageService,
        private http: HttpService

    ) {
        super();
    }

    public getUserData(force: boolean = false): Observable<UserResponseData> {
        return this.store
            .get<UserResponse>(`users/me`, this.getSubject('user-data'), force)
            .pipe(map((res) => res.data));
    }

    public getUserId(): string {
        return this._userId;
    }

    public setUserId(id: string): void {
        this.storage.addItem(STORAGE.USER_ID, id);
        this._userId = id;
    }

    public getUserName(): Observable<string> {
        return this.userNameObs$.asObservable();
    }

    public setUserName(name: string): void {
        this.storage.addItem(STORAGE.USER_NAME, name);
        this.userNameObs$.next(name);
    }

    public loadUserDataFromStorage(): void {
        const userid = this.storage.getItem(STORAGE.USER_ID);
        const userName = this.storage.getItem(STORAGE.USER_NAME);
        this._userId = userid || undefined;
        this.setUserName(userName);
    }

    public formataCPF(cpf){
        cpf = cpf.replace(/[^\d]/g, "");
      
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    public updateCorrespondencia(bit_corresp): Observable<any> {
        return this.http.post<any>('users/correspondencia', {bit_corresp}).pipe(map((res: any) => res.data));
    }

}
