import { DocumentDownloadComponent } from './modules/main/pages/document-download/document-download.component';
import { AuthAppService } from './core/guard/auth-guard.service';
import { APP_ROUTES } from './constants/routes/app-routes.const';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: APP_ROUTES.MAIN,
        pathMatch: 'full',
    },
    {
        path: APP_ROUTES.ACCOUNT,
        loadChildren: () =>
            import('./modules/account/account.module').then(
                (m) => m.AccountModule
            ),
    },
    {
        path: APP_ROUTES.MAIN,
        loadChildren: () =>
            import('./modules/main/main.module').then((m) => m.MainModule),
        canActivate: [AuthAppService],
    },
    {
        path: `${APP_ROUTES.DOCUMENT_DOWNLOAD}/:parent/:id`,
        component: DocumentDownloadComponent,
        canActivate: [AuthAppService],
    },
];

@NgModule({
    declarations: [DocumentDownloadComponent],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
