import { LoanService } from './../api/loan/loan.service';
import { ContactService } from './../api/contact/contact.service';
import { AddressService } from './../api/address/address.service';
import { UsuarioService } from './../api/usuario/usuario.service';
import { PlanService } from './../api/plan/plan.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ClearStoreService {
    constructor(
        private plan: PlanService,
        private user: UsuarioService,
        private address: AddressService,
        private contact: ContactService,
        private loan: LoanService
    ) {}

    public clearStore(): void {
        this.plan.clearStore();
        this.user.clearStore();
        this.address.clearStore();
        this.contact.clearStore();
        this.loan.clearStore();
    }
}
