import { HttpService } from './../../../core/http/http.service';
import {
    AddressData,
    CityResponse,
    CityResponseData,
    ContactData,
    ContactResponse,
    ContactResponseData,
    PepRequestData,
    StateResponse,
    StateResponseData,
    UpdateContactRequest,
} from './../../../models/contact/contact.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StoreService } from './../../../core/store/store.service';
import { Injectable } from '@angular/core';
import { StoreHandler } from '@app/core/store/store-handler.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class ContactService extends StoreHandler {
    constructor(private store: StoreService, private http: HttpService, private httpClient: HttpClient) {
        super();
    }

    public getStatesList(): Observable<StateResponseData[]> {
        return this.http
            .get<StateResponse>('states')
            .pipe(map((res) => res.data));
    }

    public getCitiesList(idEstado: any): Observable<CityResponseData[]> {
        return this.http
            .get<CityResponse>(`states/${idEstado}/cities`)
            .pipe(map((res) => res.data));
    }

    public getUserContact(
        force: boolean = false
    ): Observable<ContactResponseData> {
        return this.store
            .get<ContactResponse>(
                `users/contact`,
                this.getSubject('user-contact-response'),
                force
            )
            .pipe(map((res) => res.data));
    }

    public getUserAddress(): Observable<AddressData[]> {
        return this.getSubject<ContactResponse>('user-contact-response')
            .asObservable()
            .pipe(
                map((res) => {
                    if (res) {
                        return res.data.endereco;
                    }
                })
            );
    }
    public getUserContactData(): Observable<ContactData[]> {
        return this.getSubject<ContactResponse>('user-contact-response')
            .asObservable()
            .pipe(
                map((res) => {
                    if (res) {
                        return res.data.contato;
                    }
                })
            );
    }

    public updateContactData(data: ContactData[]): Observable<any> {
        return this.http.put(`users/contact`, { data: data });
    }

    public deleteContactData(contactId: string): Observable<any> {
        return this.http.delete(`users/contact/${contactId}`);
    }

    public updateAddressData(data: AddressData[]): Observable<any> {
        return this.http.put(`users/address`, { data: data });
    }

    public deleteAddressData(addressId: string): Observable<any> {
        return this.http.delete(`users/address/${addressId}`);
    }

    public updatePep(data: PepRequestData): Observable<any> {
        return this.httpClient.put<any>(`${environment.baseUrl}users/ppe`,
            {data},
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
            );
    }

    public getPepData(): Observable<any[]> {
        return this.http
            .get<any>('users/ppe')
            .pipe(map((res) => res.data));
    }
}
