import { StoreService } from './../../../core/store/store.service';
import {
    AddressResponseData,
    AddressResponse,
} from './../../../models/address/address.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StoreHandler } from '@app/core/store/store-handler.class';
import { HttpService } from '@app/core/http/http.service';

@Injectable({
    providedIn: 'root',
})
export class AddressService extends StoreHandler {
    constructor(private store: StoreService, private httpService: HttpService) {
        super();
    }

    public getUserAddress(): Observable<AddressResponseData> {
        return this.store
            .get<AddressResponse>(`users/address`, this.getSubject('user-address'))
            .pipe(map((res) => res.data));
    }

    public searchByCEP(cep: string): Observable<any> {
        return this.httpService.getAddress<any>(`${cep}/json`);
    }
}
