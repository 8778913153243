import { LoginService } from '@app/services/api/login/login.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { APP_ROUTES } from '@app/constants/routes/app-routes.const';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(
        private storage: StorageService,
        private router: Router,
        private login: LoginService
    ) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = this.storage.getToken();

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            });
        }

        let handled = next.handle(request);
        return handled.pipe(tap(this.successCallback(), this.errorCallback()));
    }

    private successCallback(): (response) => void {
        return (response: HttpResponse<any>) => {};
    }

    private errorCallback(): (error) => void {
        return (error: HttpErrorResponse) => {
            if (error.status === 401) {
                this.login.clearLoginData();
                this.router.navigateByUrl(APP_ROUTES.ACCOUNT);
            }
        };
    }
}
